<!--eslint-disable-->
<template>
  <div class="activity-page">
    <b-card class="" title="Monitor Activities">
      <b-card-text>
        This page shows allows you to see all different types of activities
        across users, owners, groups, networks and roles.
      </b-card-text>
      <b-card-text>You can view a user from any of the activities.</b-card-text>
    </b-card>
    <b-card class="mb-0" no-body>
      <div class="row px-2 pt-1 justify-content-end">
        <section class="col-3">
          <label>Model</label>
          <b-form-select
            v-model="filters.model"
            class="mb-2"
            @change="getMoreActivity(1)"
          >
            <b-form-select-option value="">Select a model</b-form-select-option>
            <b-form-select-option
              v-for="option in modelOptions"
              :key="option"
              :value="option"
            >
              {{ option | capEachWord }}
            </b-form-select-option>
          </b-form-select>
        </section>

        <section class="col-3">
          <label>User</label>
          <user-search-select
            :selected="selectedUser"
            @change="updateSelectedUser"
          />
        </section>

        <div class="ui-spacer" />

        <section class="col-3 d-flex justify-content-end align-items-center">
          <b-button @click="clearFilters()">Clear filters</b-button>
        </section>
      </div>
      <section>
        <b-table
          :current-page="currentPage"
          :fields="headers"
          :items="activity"
          :per-page="0"
          class="data-table"
          hover
        >
          <template #cell(created_at)="data">
            {{ data.item.created_at | formatDateTime }}
          </template>
          <template #cell(actions)="data">
            <router-link
              v-if="!data.item.user.service_account"
              :to="{ name: 'user-view', params: { uid: data.item.user.uid } }"
              class="font-weight-bold d-block text-nowrap"
            >
              View user
            </router-link>
          </template>
        </b-table>
        <b-row>
          <b-col v-if="total_count > filters.page_size" class="my-1" md="6">
            <b-pagination
              v-model="currentPage"
              :per-page="filters.page_size"
              :total-rows="total_count"
              class="my-0"
              @change="getMoreActivity"
            />
          </b-col>
          <p v-if="total_count === 0" class="col-6 ml-2 my-1">No records</p>
        </b-row>
      </section>
    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>
import OwnersService from "../../services/OwnersService";
import ActivityService from "../../services/ActivityService";
import UserSearchSelect from "@/components/input/PaginatedSearchSelect/UserSearchSelect.vue";

export default {
  name: "Activities",
  components: {
    UserSearchSelect,
  },
  props: {},
  data() {
    return {
      modelOptions: ["user", "role", "group", "network", "owner"],
      headers: [
        {
          key: "created_at",
          label: "Date",
        },
        {
          key: "message",
          label: "Message",
        },
        {
          key: "model_type",
          label: "Model Type",
        },
        {
          key: "model_name",
          label: "Model Name",
        },
        {
          key: "actions",
          label: "",
          class: "text-right",
        },
      ],
      activity: [],
      owners: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 15,
        model: "",
        user_uid: "",
        owner_uid: "",
      },
      total_count: 0,
      timeout: null,
      selectedUser: null,
    };
  },
  mounted() {
    this.getActivity();
    // this.getOwners();
  },
  methods: {
    startNewSearch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.getMoreActivity(1);
      }, 500);
    },
    getMoreActivity(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getActivity();
    },
    getActivity() {
      ActivityService.getActivity(this.filters)
        .then(res => {
          this.activity = res.data.data;
          this.filters.page = res.data.meta.page;
          this.filters.page_size = res.data.meta.page_size;
          this.total_count = res.data.meta.total_count;
        })
        .catch(err => {
          const res = err.response;
          let errorText =
            "Could not get activity, please refresh and try again";

          if (res && res.data.error) {
            errorText = res.data.error;
          }

          this.$toast.error(errorText, {
            toastClassName: ["toast-std", "warning-toast"],
          });
        });
    },
    getOwners() {
      OwnersService.getOwners({
        page: 0,
        page_size: 30,
      })
        .then(res => {
          this.owners = res.data.data;
        })
        .catch(err => {
          const res = err.response;
          let errorText = "Could not get owners, please refresh and try again";

          if (res && res.data.error) {
            errorText = res.data.error;
          }

          this.$toast.error(errorText, {
            toastClassName: ["toast-std", "warning-toast"],
          });
        });
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 25,
        model: "",
        user_uid: "",
      };
      this.selectedUser = null;

      this.getActivity();
    },
    updateSelectedUser(user) {
      this.selectedUser = user;
      if (!this.selectedUser) {
        this.filters.user_uid = "";
      } else {
        this.filters.user_uid = this.selectedUser.uid;
      }
      this.getMoreActivity(1);
    }
  },
};
</script>

<style lang="scss"></style>
